import React,{Component} from 'react';
import Navbar from './components/Navbar';
import Breadcrumb from './components/Breadcrumb';
import BlogArea from './components/Blogarea';
import FooterArea from './components/Footer.js'
class BlogPage extends Component{
  render(){
    return(
      <main rel="main">
        <Navbar navClass="home-2"/>
        <Breadcrumb pageName="Blog"/>
        <BlogArea/>
        <FooterArea/>
      </main>
    )
  }
}
export default BlogPage
