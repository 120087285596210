import React, { Component } from 'react';
import LandHeader from './Header';
import LandFeatures from './Features';
import LandFooter from './Footer';
import LandingAwesomeDemo from './AwesomeDemo';

//Load landing page css
import './Land.scss';

class LandingPage extends Component {
  render() {
    return (
      <div className="landing-page-wrapper">
        <LandHeader/>
        <LandingAwesomeDemo/>
        <LandFeatures/>
        <LandFooter/>
      </div>
    );
  }
}

export default LandingPage;
