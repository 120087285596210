import React,{Component} from 'react';

class HeaderThree extends Component{
  render(){
    return(
      <header className="header-area  header-style-four header-bg" id="home">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="header-inner">
                        <h1 className="title wow fadeInDown" >Showcase Your App With Apptidy</h1>
                        <p>Apprtidy is the best app landing page which will help you showcase your business, lifestyle, social, or shopping app in the best possible manner.</p>
                          <div className="make-free-trial-area">
                               <form action="#!" className="free-trail-form">
                                   <div className="form-group">
                                       <input type="text" className="form-control" placeholder="Enter Your Email"/>
                                   </div>
                                   <button type="submit" className="submit-btn">Free Trial</button>
                               </form>
                          </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="right-content-area">
                        <img src={require('../../assets/img/header-right.png')} alt="header right area"/>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
  }
}

export default HeaderThree;
