import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class AppFeatures extends Component{
  state ={
    sectionTitle: {
      title: '蝶化秀 AR 特点',
      subtitle: 'CORE FEATURES',
      paragraph: 'Web AR 将会在 5G 时代越来越普及'
    },
    appFeatures: [
      {
        icon: 'flaticon-education',
        title: '扫码即用',
        paragraph: '用手机微信、QQ、浏览器等软件扫码打开链接即可使用 AR ，无需下载 APP ，操作更方便。',
        theme : 'green-gd'
      },
      {
        icon: 'flaticon-support',
        title: '模型展示',
        paragraph: '高效的 WebGL 渲染引擎实时渲染 3D 模型，高度还原真实场景。',
        theme: 'blue-gd'
      },
      {
        icon: 'flaticon-vision',
        title: '图像追踪',
        paragraph: '拥有高效的图像追踪能力，手机视角全方位移动观看 3D 模型。',
        theme: 'purple-gd'
      },
      {
        icon: 'flaticon-shield',
        title: '安全加密',
        paragraph: 'AR 内容经过云服务加密处理，防止资源外泄，保护用户的知识产权。',
        theme: 'pink-gd'
      },
    ]
  }
  render(){
    return(
      <section className="app-feature-aera">
        <div className="container border-bottom">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                  <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleClass=""/>
                </div>
            </div>
            <div className="row">
            {
              this.state.appFeatures.map((feature,index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                    <div className={`single-app-features-item ${feature.theme}`}>
                        <div className="icon">
                            <i className={feature.icon}></i>
                        </div>
                        <div className="content">
                            <h4 className="title">{feature.title}</h4>
                            <p>{feature.paragraph}</p>
                        </div>
                    </div>
                </div>
              ))
            }


            </div>
        </div>
    </section>
    )
  }
}

export default AppFeatures;
