import React,{Component} from 'react';
import Sidebar from './Sidebar';

class BlogDetailsArea extends Component{
  render(){
    return(
      <section className="blog-details-page-content-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                  <div className="blog-details-content-area">
                      <div className="entry-content">
                          <div className="thumb">
                              <img src={require('../../assets/img/blog/01.jpg')} alt="blog single"/>
                              <div className="time">
                                  <span className="date"> 12</span>
                                  <span className="month">DEC</span>
                              </div>
                          </div>
                          <ul className="post-meta">
                              <li><i className="icofont-speech-comments"></i> 20 Comments</li>
                              <li><i className="icofont-eye-alt"></i> 466 Views</li>
                          </ul>
                          <h3 className="title">Why Hiring an Ideal Mobile App Development Company Is Essential for Business Now </h3>
                          <p>Mobile phones have become as ubiquitous as wristwatches once were. Men, women and even children are today connected to others from across the world, all thanks to the new smartphone technology. Smartphones are virtual computers that you can carry around in your pocket easily. These mobile phones not only allow you to talk to another person but also provide you a world of information, all in the palm of your hands.</p>
                          <p>According to a report by Statista, as of January 2018, there were over 3.7 billion individual mobile users in the world. Also, thanks to the advanced smartphone technology in use today, mobile sm artphones accounted for nearly 50% of the total global internet usage volume. There are numerous such facts and figures which show clearly the still-increasing numbers of smartphone users worldwide.</p>
                          <p>We all know that a smartphone is hardly of much use unless and until you have smartphone applications, or 'Apps,' installed in it. Mobile apps are simply software that is designed to run on mobile platforms, such as Android, iOS, Windows, etc. With the rise in numbers of smartphone users, mobile apps have become an essential aspect of conducting commerce, from the ground level up to B2B level.</p>
                          <blockquote>
                              Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam
                              tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit.
                              <div className="icon"><i className="icofont-quote-left"></i></div>
                          </blockquote>
                          <p>Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam
                              tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit.</p>
                          <p>Seeing the immense efficiency, convenience and vital connectivity that smartphone mobile apps provide today small and large business of all kinds are in search of the best custom Android app development company.</p>
                      </div>
                      <div className="entry-footer">
                          <div className="left-content">
                              <span className="posted_by">Posted By : <a href="#!">Dennis Allison </a></span>
                          </div>
                          <div className="right-content">
                              <ul>
                                  <li className="title">Share:</li>
                                  <li><a href="#!"><i className="icofont-facebook"></i></a></li>
                                  <li><a href="#!"><i className="icofont-twitter"></i></a></li>
                                  <li><a href="#!"><i className="icofont-pinterest"></i></a></li>
                              </ul>
                          </div>
                      </div>
                      <div className="entry-comment">
                          <h4 className="title">02 Comments</h4>
                          <ul>
                              <li>
                                  <div className="single-comment-item">
                                      <a href="#!" className="reply"><i className="icofont-reply-all"></i></a>
                                      <div className="thumb">
                                          <img src={require('../../assets/img/comment/01.jpg')} alt="comment"/>
                                      </div>
                                      <div className="content">
                                          <h4 className="title">Dixie Cortez</h4>
                                          <span className="time">Dec 23, 2018 - 4:00AM</span>
                                          <p>Purus nulla dapibus nunc, eu interdum dolor urna eget risus. In in lorem ultricies, suscipit arcu ullamcorper, sodales sapien. Nam a sem enim. Curabitur sit amet euismod nisi. </p>

                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="single-comment-item reply">
                                      <a href="#!" className="reply"><i className="icofont-reply-all"></i></a>
                                      <div className="thumb">
                                          <img src={require('../../assets/img/comment/01.jpg')} alt="comment"/>
                                      </div>
                                      <div className="content">
                                          <h4 className="title">Brett Cain</h4>
                                          <span className="time">Dec 23, 2018 - 4:00AM</span>
                                          <p>Purus nulla dapibus nunc, eu interdum dolor urna eget risus. In in lorem ultricies, suscipit arcu ullamcorper, sodales sapien. Nam a sem enim. Curabitur sit amet euismod nisi. </p>

                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="single-comment-item">
                                      <a href="#!" className="reply"><i className="icofont-reply-all"></i></a>
                                      <div className="thumb">
                                          <img src={require('../../assets/img/comment/01.jpg')} alt="comment"/>
                                      </div>
                                      <div className="content">
                                          <h4 className="title">Rex Coleman </h4>
                                          <span className="time">Dec 23, 2018 - 4:00AM</span>
                                          <p>Purus nulla dapibus nunc, eu interdum dolor urna eget risus. In in lorem ultricies, suscipit arcu ullamcorper, sodales sapien. Nam a sem enim. Curabitur sit amet euismod nisi. </p>

                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                      <div className="entry-commentform">
                          <h4 className="title">Leave A Comments</h4>
                          <form action="#!" className="commentform">
                              <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Full Name"/>
                              </div>
                              <div className="form-group">
                                  <input type="email" className="form-control" placeholder="Email Address"/>
                              </div>
                              <div className="form-group">
                                  <input type="tel" className="form-control" placeholder="Phone Number"/>
                              </div>
                              <div className="form-group textarea">
                                 <textarea className="form-control" cols="30" rows="5" placeholder="Message"></textarea>
                              </div>
                              <div className="form-group">
                                  <button className="btn btn-submit" type="submit">Post Comment</button>
                              </div>
                          </form>
                      </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Sidebar/>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default BlogDetailsArea;
