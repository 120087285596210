import React,{Component} from 'react';

class Breadcrumb extends Component {
  render(){
    return(
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breadcrumb-inner">
                        <h1 className="page-title">{this.props.pageName}</h1>
                        <ul className="page-navigation">
                            <li><a href="#!"><i className="icofont-home"></i> Home</a></li>
                            <li>{this.props.pageName}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default Breadcrumb;
