import React,{Component} from 'react';
import SectionTitle from './SectionTitle';
import CountUp from 'react-countup';
import $ from 'jquery';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup/dist/jquery.magnific-popup.js';

class VideoAndCounter extends Component{

  state = {
    sectionTitle:{
      title: 'To Use Our Application',
      subtitle: 'Amazing Features to convince you',
      paragraph: 'This is yet another place to highlight feature your great app and talk bit about compatibility maybe who knows, the decision is yours to make! Don\'t be hurry.',
      titleclassName: 'p-width-lg extra'
    },
    counterUp:[
      {
        icon : 'flaticon-global',
        title: 'Happy Users',
        countNumber: 1971
      },
      {
        icon : 'flaticon-download',
        title: 'Download',
        countNumber: 1791
      },
      {
        icon : 'flaticon-good',
        title: 'Total Like',
        countNumber: 1289
      },
      {
        icon : 'flaticon-trophy',
        title: 'Award Win',
        countNumber: 16
      },
    ]
  }

  componentDidMount(){
    $('.video-play-btn').magnificPopup({
        type: 'video'
    });
  }

  render(){
    return (
      <section className="video-and-counter-area">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="video-area-wrapper">
                        <div className="img-wrapper">
                            <img src={require('../../assets/img/video-section-image.jpg')} alt="video section"/>
                            <div className="hover">
                                <a href="https://www.youtube.com/watch?v=SVq46QqB6zA" className="video-play-btn mfp-iframe"><i className="icofont-ui-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

              {
                this.state.counterUp.map((countItem,index) => (
                  <div className="col-lg-3 col-md-6" key={index}>
                      <div className="single-counterup-item">
                          <div className="icon">
                              <i className={countItem.icon}></i>
                          </div>
                          <div className="content">
                              <CountUp className='countnum' end={countItem.countNumber} />
                              <h4 className="title">{countItem.title}</h4>
                          </div>
                      </div>
                  </div>
                ))
              }
            </div>
        </div>
    </section>
    )
  }
}

export default VideoAndCounter;
