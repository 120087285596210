import React,{Component} from 'react';
import LandingPage from './land/Land';


class App extends Component{
  render(){
    return (
      <div className="App">
          <LandingPage/>
      </div>
    )
  }
}
export default App;
