import React,{Component} from 'react';

class HeaderOne extends Component{
  render(){
    return(
      <header className="header-area header-bg-2" id="home">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="header-inner">
                        <h1 className="title wow fadeInDown" >Showcase Your App With Apptidy</h1>
                        <p>Apprtidy is the best app landing page which will help you showcase your business, lifestyle, social, or shopping app in the best possible manner.</p>
                        <div className="btn-wrapper wow fadeInUp" >
                            <a href="#!" className="boxed-btn btn-rounded"><i className="icofont-brand-apple"></i> App Store</a>
                            <a href="#!" className="boxed-btn btn-rounded blank"><img src={require('../../assets/img/icons/google-play.png')} alt=""/> Play Store</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="right-content-area">
                        <img src={require('../../assets/img/header-right.png')} alt="header right area"/>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
  }
}

export default HeaderOne;
