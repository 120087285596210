import React,{Component} from 'react';

class Sidebar extends Component{
  state = {
    latestPost: [
      {
        img:require('../../assets/img/latest-post/01.jpg'),
        title: '10 Ways to Market Your Killian',
        author: 'Sean Killian'
      },
      {
        img:require('../../assets/img/latest-post/02.jpg'),
        title: '3 Reasons Why Apps Merrick',
        author: 'Sean Killian'
      },
      {
        img:require('../../assets/img/latest-post/03.jpg'),
        title: 'How to Build an iPhone Justin',
        author: 'Sean Killian'
      },
      {
        img:require('../../assets/img/latest-post/04.jpg'),
        title: 'How To Price Your Mobile Oleg',
        author: 'Sean Killian'
      },
    ]
  }
  render(){
    return(
      <div className="sidebar">
          <div className="widget-area search">
              <div className="widget-body">
                  <form action="blog.html" className="searchform">
                      <div className="form-group">
                          <input type="text" className="form-control" placeholder="Search"/>
                      </div>
                      <button type="submit" className="btn-submit"><i className="icofont-search-1"></i></button>
                  </form>
              </div>
          </div>
          <div className="widget-area category">
              <div className="widget-title">
                  <h4 className="title">Categories</h4>
              </div>
              <div className="widget-body">
                  <ul>
                      <li><a href="#!">VR Feature <span className="right">(33)</span></a></li>
                      <li><a href="#!">Drones <span className="right">(77)</span></a></li>
                      <li><a href="#!">Smartwatch <span className="right">(49)</span></a></li>
                      <li><a href="#!">Businesss  <span className="right">(70)</span></a></li>
                      <li><a href="#!">Marketing  <span className="right">(89)</span></a></li>
                      <li><a href="#!">Inspiration <span className="right">(93)</span></a></li>
                      <li><a href="#!">Design <span className="right">(38)</span></a></li>
                  </ul>
              </div>
          </div>
          <div className="widget-area latest-post">
              <div className="widget-title">
                  <h4 className="title">Latest Posts</h4>
              </div>
              <div className="widget-body">
                  <ul>
                    {
                      this.state.latestPost.map( (postItem,index) => (
                        <li key={index}>
                            <div className="single-latest-post">
                                <div className="thumb">
                                    <img src={postItem.img} alt="latest post"/>
                                </div>
                                <div className="content">
                                   <a href="#!"> <h4 className="title">{postItem.title}</h4></a>
                                    <span className="posted-by">By <a href="#!">{postItem.author}</a></span>
                                </div>
                            </div>
                        </li>
                      ) )
                    }
                  </ul>
              </div>
          </div>
          <div className="widget-area category">
              <div className="widget-title">
                  <h4 className="title">Archives</h4>
              </div>
              <div className="widget-body">
                  <ul>
                      <li><a href="#!">May 2018 <span className="right">(33)</span></a></li>
                      <li><a href="#!">Jun 2018 <span className="right">(77)</span></a></li>
                      <li><a href="#!">Jul 2018 <span className="right">(49)</span></a></li>
                      <li><a href="#!">Feb 2017  <span className="right">(70)</span></a></li>
                      <li><a href="#!">Apr 2017 <span className="right">(89)</span></a></li>
                  </ul>
              </div>
          </div>
          <div className="widget-area tags">
              <div className="widget-title">
                  <h4 className="title">Tags</h4>
              </div>
              <div className="widget-body">
                  <ul>
                      <li><a href="#!">App</a></li>
                      <li><a href="#!">Store</a></li>
                      <li><a href="#!">marketing</a></li>
                      <li><a href="#!">IOS</a></li>
                      <li><a href="#!">Android</a></li>
                  </ul>
              </div>
          </div>
      </div>
    )
  }
}

export default Sidebar;
