import React,{Component} from 'react';
import SectionTitle from './SectionTitle';
import Slick from 'react-slick';
import "slick-carousel/slick/slick.css";


class ScreenshortArea extends Component{
  state = {
    sectionTitle: {
      title: '效果欣赏',
      subtitle: 'Screenshots',
      paragraph: 'This is yet another place to highlight feature your great app and talk bit about compatibility maybe who knows, the decision is yours to make! Don\'t be hurry.',
      titleClass: 'p-width-lg'
    },
    screenshorts:[
      {
        img: require('../../assets/img/screenshort/1.jpg')
      },
      {
        img: require('../../assets/img/screenshort/2.jpg')
      },
      {
        img: require('../../assets/img/screenshort/3.jpg')
      },
      {
        img: require('../../assets/img/screenshort/4.jpg')
      },
      {
        img: require('../../assets/img/screenshort/5.jpg')
      },
      {
        img: require('../../assets/img/screenshort/6.jpg')
      },
    ]
  }
  render(){
    var settings = {
      dots:false,
      infinite:true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows:false,
    }
    return(
      <section className="screenshort-area screenshort-bg" id="screenshort">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                  <SectionTitle subtitle={this.state.sectionTitle.subtitle} title={this.state.sectionTitle.title} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                </div>
            </div>
            <div className="row">
                  <div className="col-lg-12">
                      <div className="sc-carousel-wrapper">
                        <div className="screenshort-carousel">
                          <Slick {...settings}>
                            {
                              this.state.screenshorts.map((screen,index) => (
                                <div className="single-screenshort-item" key={index}>
                                    <img src={screen.img} alt="screenshort item"/>
                                </div>
                              ))
                            }
                          </Slick>
                        </div>
                      </div>
                  </div>
              </div>
        </div>
    </section>
    )
  }
}
export default ScreenshortArea;
