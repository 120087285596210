import React,{Component} from 'react';

class LandingSectionTitle extends Component{
  render() {
    return(
      <div className="section-title">
          <span className="subtitle">{this.props.subtitle}</span>
          <h2 className="title">{this.props.title}</h2>
      </div>
    )
  }
}

export default LandingSectionTitle;
