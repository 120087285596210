import React,{Component} from 'react';
import SectionTitle from './SectionTitle';
import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";

class WhatWeOffer extends Component{

  state = {
    sectionTitle:{
      title: '免费的专属主页',
      subtitle: '',
      paragraph: '现在加入蝶化秀，即刻免费拥有您的 Web AR 主页，让您专注推广自己的品牌。',
      titleClass: 'p-width-lg white'
    },
    weOfferItem:[
      {
        theme : 'bg1',
        icon: 'icofont-rocket-alt-2',
        title: '专属链接',
        paragraph: '您将拥有属于个人或企业的 Web AR 网页地址，您可以将链接制作成二维码印刷在书上，也可以通过社交软件分享给朋友，您的用户打开链接就能看到您的主页。'
      },
      {
        theme : 'bg2',
        icon: 'icofont-responsive',
        title: '专属名称',
        paragraph: '网页的名称由您来决定。可以用公司名称、个人名字、也可以用更加具有宣传力的广告词，您说了算。'
      },
      {
        theme : 'bg3',
        icon: 'icofont-cloud-upload',
        title: '专属横幅',
        paragraph: '横幅是显示在主页抬头位置的图片，您可以在主页中展示自己设计的横幅，这对您的品牌推广有极大的促进作用。'
      },
      {
        theme : 'bg4',
        icon: 'icofont-live-support',
        title: '专属商品',
        paragraph: '在主页中只会展示您上架的商品，让用户完全沉浸在您的品牌范围中，而您只需要推广。'
      }
    ],
    screenShortItem: [
      {
        img: require('../../assets/img/we-offer/homepage.jpg')
      },
      // {
      //   img: require('../../assets/img/we-offer/02.jpg')
      // },
      // {
      //   img: require('../../assets/img/we-offer/03.jpg')
      // },
    ]
  }


  render(){

    var settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        };

    return (
      <section className="we-offer-area" id="feature">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                    <SectionTitle subtitle={this.state.sectionTitle.sutitle} title={this.state.sectionTitle.title} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="left-content-area">
                            <div className="we-offer-carousel">
                              <SlickSlider {...settings}>
                                {
                                  this.state.screenShortItem.map((screenShort,index) => (
                                    <div className="single-we-offer" key={index}>
                                        <img src={screenShort.img} alt="wee offer"/>
                                    </div>
                                  ))
                                }
                              </SlickSlider>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="right-content-area">
                            <ul id="we-offer-item">
                            {
                              this.state.weOfferItem.map((offerItem,index) => (
                                <li  key={index}>
                                    <div className={`single-we-offer-item ${offerItem.theme}`}>
                                        <div className="icon">
                                              <i className={offerItem.icon}></i>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">{offerItem.title}</h4>
                                            <p>{offerItem.paragraph}</p>
                                        </div>
                                    </div>
                                </li>
                              ))
                            }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    )
  }
}


export default WhatWeOffer;
