import React,{Component} from 'react';

const FooterCopytight = '© All Right Reserved ir-tech';

class LandFooter extends Component{
  render(){
    return (
      <footer className="footer-area-start">
    		<div className="container">
    			<div className="row text-center">
    				<div className="col-lg-12">{FooterCopytight}</div>
    			</div>
    		</div>
    </footer>
    )
  }
}

export default LandFooter;
