import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class PricingArea extends Component{
  state = {
    sectionTitle: {
      subtitle: 'asset store',
      title: '专业 3D 资源库',
      paragraph: '我们为您提供海量专业、正版的 3D 资源库，让您在制作 AR 内容的时候更加省时省力。',
      titleClass: 'p-width-lg'
    },
    priceTable: [
      {
        title: 'start',
        price: '15',
        month: '/Mon',
        rules: ['5 GB Space','5 Subdomain Unlimited','Easy to Customize','Unlimited Users','Highest Speed','Easy to Customize','Support Unlimited User'],
        button:{
          text: 'Get Started',
          link: '#!'
        }
      },
      {
        title: 'Pro',
        price: '99',
        month: '/Mon',
        rules: ['10 GB Space','15 Subdomain Unlimited','Easy to Customize','Unlimited Users','Highest Speed','Easy to Customize','Support Unlimited User'],
        button:{
          text: 'Get Started',
          link: '#!'
        }
      }
    ]
  }
  render(){
    return(
      <section className="pricing-plan-area" id="pricing">
          <div className="right-image">
              <div className="img-wrapper">
                  <img src={require('../../assets/img/bg/pricing-right-image.png')} alt="pricing plan right"/>
              </div>
          </div>
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                      <SectionTitle subtitle={this.state.sectionTitle.subtitle} title={this.state.sectionTitle.title} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass} />
                  </div>
              </div>
              <div className="row">
                  <div className="img-wrapper">
                      <img src={require('../../assets/img/assetstore.png')} alt="pricing plan right"/>
                  </div>
              {/*{*/}
              {/*  this.state.priceTable.map((priceItem,index) => (*/}
              {/*    <div className="col-lg-4 col-md-6" key={index}>*/}
              {/*        <div className="single-price-plan">*/}
              {/*            <div className="price-header">*/}
              {/*                <h4 className="title">{priceItem.title}</h4>*/}
              {/*            </div>*/}
              {/*            <div className="price-area">*/}
              {/*                <span className="dollar">$</span>*/}
              {/*                <span className="price">{priceItem.price}</span>*/}
              {/*                <span className="month">{priceItem.month}</span>*/}
              {/*            </div>*/}
              {/*            <div className="price-body">*/}
              {/*                <ul>*/}
              {/*                {*/}
              {/*                  priceItem.rules.map((rule,index) => (*/}
              {/*                      <li key={index}>{rule}</li>*/}
              {/*                  ))*/}
              {/*                }*/}
              {/*                </ul>*/}
              {/*            </div>*/}
              {/*            <div className="price-footer">*/}
              {/*                <a href={priceItem.button.link} className="boxed-btn">{priceItem.button.text}</a>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*  ))*/}
              {/*}*/}
              </div>
          </div>
      </section>
    )
  }
}

export default PricingArea;
