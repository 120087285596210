import React,{Component} from 'react';

import tellIcon from '../../assets/img/icons/tell.svg';
import mailIcon from '../../assets/img/icons/mail.svg';

class FooterArea extends Component{
  render(){
    return (
      <footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    {/*<div className="footer-top">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-lg-3 col-md-6">*/}
                    {/*            <div className="footer-widget widget">*/}
                    {/*                <h4 className="widget-title">Apptidy</h4>*/}
                    {/*                <ul className="menu">*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Features</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Security</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Download</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Apptidy Web</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Business</a> </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-3 col-md-6">*/}
                    {/*            <div className="footer-widget widget">*/}
                    {/*                <h4 className="widget-title">Company</h4>*/}
                    {/*                <ul className="menu">*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> About</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> News</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Blog</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Contact</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Terms</a> </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-3 col-md-6">*/}
                    {/*            <div className="footer-widget widget">*/}
                    {/*                <h4 className="widget-title">Download</h4>*/}
                    {/*                <ul className="menu">*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Mac/Pc</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Android</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Iphone</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Windows Phone</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Nokia</a> </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-3 col-md-6">*/}
                    {/*            <div className="footer-widget widget">*/}
                    {/*                <h4 className="widget-title">Support</h4>*/}
                    {/*                <ul className="menu">*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Helpdek</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Forum</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Partner</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Testimonial</a> </li>*/}
                    {/*                    <li> <a href="#!"><i className="icofont-rounded-double-right"></i> Resource</a> </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="footer-bottom">
                        <div className="copyright-area">
                            <div className="center-content-area">
                                <img className="tell-icon" src={tellIcon}/>18628059118
                                <img className="mail-icon" src={mailIcon}/>zhixintechar@163.com
                            </div>
                            <div className="center-content-area">
                                Copyright &copy; 2020 成都志心科技有限公司
                            </div>
                            <a className="record-number" href="http://beian.miit.gov.cn" target="_blank">蜀ICP备20011660号</a>
                            <a className="record-number">    川网文 (2020) 2269-499</a>
                            {/*<div className="right-content-area">*/}
                            {/*    <ul className="social-icon">*/}
                            {/*        <li><a href="#!"><i className="icofont-facebook"></i></a></li>*/}
                            {/*        <li><a href="#!"><i className="icofont-twitter"></i></a></li>*/}
                            {/*        <li><a href="#!"><i className="icofont-instagram"></i></a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
  }
}

export default FooterArea;
