import React,{Component} from 'react';

class DownloadArea extends Component {
  state = {
    sectionTitle: {
      title: 'Get The App on',
      subtitle: 'download',
      paragraph: 'This is yet another place to highlight feature your great app and talk bit about compatibility maybe who knows, the decision is yours to make! Don\'t be hurry.',
      titleClass : 'p-width-lg'
    }
  }
  render(){
    return(
      <section className="download-area">
        <div className="bottom-bg-image">
            <img src={require('../../assets/img/bg/download-area-bg.png')} alt="download bottom"/>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-title p-width-lg ">
                        <span className="subtitle">{this.state.sectionTitle.subtitle}</span>
                        <h2 className="title">{this.state.sectionTitle.title}</h2>
                        <p>{this.state.sectionTitle.paragraph} </p>
                        <div className="btn-wrapper wow fadeInUp" >
                            <a href="#!" className="boxed-btn btn-rounded"><i className="icofont-brand-apple"></i> App Store</a>
                            <a href="#!" className="boxed-btn btn-rounded blank"><img src={require('../../assets/img/icons/google-play.png')} alt=""/> Play Store</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="bottom-image">
                        <img src={require('../../assets/img/download-area-bottom.png')} alt="download area bottom "/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}


export default DownloadArea;
