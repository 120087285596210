import React, {Component} from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';

class Navbar extends Component {

    componentDidMount() {
        $(document).on('click', '#primary-menu li a[data-toggle="dropdown"]', function (e) {
            e.preventDefault();
        });
        $(document).on('click', '#primary-menu li a', function (e) {
            var anchor = $(this).attr('href');
            var link = anchor.slice(0, 1);
            if ('#' === link) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $(anchor).offset().top
                }, 1000);
                $(this).parent().addClass('active').siblings().removeClass('active');
            }
        });
    }//end couponentDidMount

    state = {
        navbarClass: this.props.navClass ? this.props.navClass : false
    }

    render() {
        return (

            <nav className={`navbar navbar-area navbar-expand-lg navbar-light ${this.state.navbarClass}`}>
                <div className="container">
                    <a className="navbar-brand logo" href="index.html">
                        <img src={require('../../assets/img/logo_white_400.png')} className="white" alt="logo"/>
                        <img src={require('../../assets/img/logo.png')} className="black" alt="logo"/>
                    </a>
                    {/*<div className="collapse navbar-collapse" id="apptidy">*/}
                    {/*    <ul className="navbar-nav" id="primary-menu">*/}
                    {/*        <li className="nav-item active">*/}
                    {/*            <a className="nav-link pl-0" href="#home">Home*/}
                    {/*                <span className="sr-only">(current)</span>*/}
                    {/*            </a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a className="nav-link" href="#feature">Features</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a className="nav-link" href="#screenshort">Screens</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a className="nav-link" href="#pricing">Pricing</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a className="nav-link" href="#team">Team</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item dropdown">*/}
                    {/*            <a className="nav-link dropdown-toggle" href="#!" role="button" data-toggle="dropdown"*/}
                    {/*               aria-haspopup="true" aria-expanded="false">Blog</a>*/}
                    {/*            <div className="dropdown-menu">*/}
                    {/*                <Link className="dropdown-item" to='/blog'>Blog</Link>*/}
                    {/*                <Link className="dropdown-item" to='/blog-details'>Blog Details</Link>*/}
                    {/*            </div>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a className="nav-link" href="#contact">Contact</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item navbar-btn-wrapper">*/}
                    {/*            <a className="nav-link boxed-btn blank" href="#!">Download</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#apptidy"*/}
                    {/*        aria-controls="apptidy" aria-expanded="false" aria-label="Toggle navigation">*/}
                    {/*    <span className="navbar-toggler-icon"></span>*/}
                    {/*</button>*/}
                </div>
            </nav>
        )
    }
}


export default Navbar;
