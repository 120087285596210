import React,{Component} from 'react';
import SectionTitle from './SectionTitle';
import Slick from 'react-slick';
import "slick-carousel/slick/slick.css";

class TestimonialArea extends Component {
  state = {
    sectionTitle:{
      title: 'App Reviews',
      subtitle: 'testimonials',
      paragraph: 'This is yet another place to highlight feature your great app and talk bit about compatibility maybe who knows, the decision is yours to make! Don\'t be hurry.',
      titleClass: 'p-width-lg'
    },
    testimonial: [
      {
        img: require('../../assets/img/testimonial/01.jpg'),
        name: 'Harry Neal' ,
        designation: 'Customer',
        paragraph: 'Morbi quis lacus maximus urna eleifend pellentesque eget sed odio. Sed vel arcu a mi malesuada rutrum eget fringilla libero. Mauris efficitur vestibulum dui, dignissim viverra mi dignissim ut.'
      },
      {
        img: require('../../assets/img/testimonial/01.jpg'),
        name: 'Betsy Gerez' ,
        designation: 'Customer',
        paragraph: 'Morbi quis lacus maximus urna eleifend pellentesque eget sed odio. Sed vel arcu a mi malesuada rutrum eget fringilla libero. Mauris efficitur vestibulum dui, dignissim viverra mi dignissim ut.'
      },
      {
        img: require('../../assets/img/testimonial/01.jpg'),
        name: 'Eric Palmer' ,
        designation: 'Customer',
        paragraph: 'Morbi quis lacus maximus urna eleifend pellentesque eget sed odio. Sed vel arcu a mi malesuada rutrum eget fringilla libero. Mauris efficitur vestibulum dui, dignissim viverra mi dignissim ut.'
      },
    ]
  }
  render(){
    var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        };
    return(
      <section className="testimonial-area">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                      <div className="testimonial-carousel">
                        <Slick {...settings}>
                            {
                              this.state.testimonial.map((testim,index) => (
                                <div className="single-testimonial-item" key={index}>
                                    <div className="thumb">
                                        <img src={testim.img} alt="testimonial"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="author-name">{testim.name}</h4>
                                        <span className="post">{testim.designation}</span>
                                        <p>{testim.paragraph}</p>
                                    </div>
                                </div>
                              ))
                            }
                        </Slick>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
  }
}
export default TestimonialArea;
