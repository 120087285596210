import React,{Component} from 'react';
import LandingSectionTitle from './SectionTitle';
class LandFeatures extends Component{

  state = {
    sectionTitle:{
      title: 'Awesome Features',
      subtitle: 'WHAT YOU GET'
    },
    features: [
    {
      icon: require(`./assets/icon/vector.png`),
      title: '04 Unique home Design'
    },
    {
      icon: require('./assets/icon/color-palette.png'),
      title: 'Blog & Blog Detils'
    },
    {
      icon: require('./assets/icon/settings.png'),
      title: 'Easy To Customize'
    },
    {
      icon: require('./assets/icon/coding.png'),
      title: 'W3c Validated'
    },
    {
      icon: require('./assets/icon/css-document.png'),
      title: 'CSS3 Animation'
    },
    {
      icon: require('./assets/icon/coding-2.png'),
      title: 'Valid & Clean Code'
    },
    {
      icon: require('./assets/icon/responsive.png'),
      title: 'Fully Responsive'
    },
    {
      icon: require('./assets/icon/contract.png'),
      title: 'Well Documented'
    },
    {
      icon: require(`./assets/icon/support.png`),
      title: '24/7 Support'
    }
  ]}


  render(){
    return (
      <div className="feature-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                  <LandingSectionTitle title="Awesome Features" subtitle="WHAT YOU GET"/>
                </div>
                {
                  this.state.features.map((feature,index) => (
                    <div className="col-md-4 col-sm-6" key={index}>
                        <div className="single-feature-list">
                            <div className="icon">
                                <img src={feature.icon} alt={feature.title}/>
                            </div>
                            <div className="content">
                                <h4 className="title">{feature.title}</h4>
                            </div>
                        </div>
                    </div>
                  ))
                }
            </div>
        </div>
    </div>
    )
  }
}

export default LandFeatures;
