import React,{Component} from 'react';
import LandingSectionTitle from './SectionTitle';
import {Link} from 'react-router-dom';

class LandingAwesomeDemo extends Component {
  state = {
    sectionTitle: {
      title : 'Awesome Demos',
      subtitle: 'Quality Variant'
    },
    demos:[
      {
        img: require('./assets/demo/01.jpg'),
        link: '/home-1',
        title: 'Home 01'
      },
      {
        img: require('./assets/demo/02.jpg'),
        link: '/home-2',
        title: 'Home 02'
      },
      {
        img: require('./assets/demo/05.jpg'),
        link: '/home-3',
        title: 'Home 03'
      },
      {
        img: require('./assets/demo/07.jpg'),
        link: '/home-4',
        title: 'Home 04'
      },
      {
        img: require('./assets/demo/03.jpg'),
        link: '/blog',
        title: 'Blog'
      },
      {
        img: require('./assets/demo/04.jpg'),
        link: '/blog-details',
        title: 'Blog Details'
      },
    ]
  }
  render(){
    return (
      <div className="template-showcase" id="explore">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                  <LandingSectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} />
                </div>

                {
                  this.state.demos.map((demo,index) => (
                    <div className="col-lg-6 col-sm-6" key={index}>
                        <div className="single-demo-item">
                            <div className="thumb">
                                <img src={demo.img} alt={demo.title} />
                                <div className="hover">
                                    <div className="hover-inner">
                                      <Link to={demo.link} className="boxed-btn" target="_blank">View Demo</Link>
                                        <h2 className="title">{demo.title}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  ))
                }




            </div>
        </div>
    </div>
    )
  }
}

export default LandingAwesomeDemo;
