import React, {Component} from 'react';

function isIPad() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('iPad') > -1) {
        return true;
    }
    if (ua.indexOf('Macintosh') > -1) {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {}
    }
    return false;
}

var os = function() {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian && !isIPad();
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid : isAndroid,
        isPc : isPc
    };
}();

class HeaderTwo extends Component {
    render() {
        return (
            <header className="header-area header-bg" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="header-inner">
                                <h1 className="title wow fadeInDown">蝶化秀 AR 云平台</h1>
                                <p>蝶化秀是 5G 时代领先的 Web AR 云平台，为出版社、学校、企业等进行 AR 数字化升级。</p>
                                {
                                    os.isPc ? (
                                        <div className="btn-wrapper wow fadeInUp">
                                            <img src={require('../../assets/img/qrcode.jpg')} alt="qrcode area"/>
                                        </div>
                                    ) : (
                                        <div className="btn-wrapper wow fadeInUp">
                                            <a href="https://www.zxdhx.com/product"
                                               className="boxed-btn btn-rounded">立即体验</a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right-content-area">
                                <img src={require('../../assets/img/mobile_screen.png')} alt="header right area"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderTwo;
