import React,{Component} from 'react';

class ContactArea extends Component {
  state = {
    sectionTitle: {
      title: 'AR 编辑器',
      subtitle: 'AR Editor',
      paragraph: '我们为您提供了免费、专业的 AR 内容可视化编辑工具，您可以用它生产并发布 AR 内容。'
    }
  }
  render(){
    return (
      <section className="contact-area" id="contact">
        <div className="container">
            <div className="row reorder-xs">
                <div className="col-lg-6">
                    <div className="left-content-area">
                        <div className="section-inner-title">
                            <span className="subtitle">{this.state.sectionTitle.subtitle}</span>
                            <h3 className="title">{this.state.sectionTitle.title}</h3>
                            <p>{this.state.sectionTitle.paragraph}</p>
                        </div>
                        <div className="img-wrapper">
                            <img src={require('../../assets/img/editor.png')} alt="contact right"/>
                        </div>
                        {/*<div className="contact-form-wrapper">*/}
                        {/*    <form action="#!" className="contact-form" id="contact_form_submit">*/}
                        {/*        <div className="form-group">*/}
                        {/*            <input type="text" id="uname" className="form-control" placeholder="Name *"/>*/}
                        {/*        </div>*/}
                        {/*        <div className="form-group">*/}
                        {/*            <input type="email" id="email" className="form-control" placeholder="Email *"/>*/}
                        {/*        </div>*/}
                        {/*        <div className="form-group textarea">*/}
                        {/*            <textarea id="message" cols="30" rows="5" className="form-control" placeholder="Message *"></textarea>*/}
                        {/*        </div>*/}
                        {/*        <button type="submit" className="submit-btn" >Submit Now</button>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-content-aera">
                        <div className="img-wrapper">
                            <img src={require('../../assets/img/contact_right.jpg')} alt="contact right"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default ContactArea;
