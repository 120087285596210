import React,{Component} from 'react';
import Sidebar from './Sidebar';

class BlogArea extends Component{
  state = {
    blogPosts : [
      {
        img: require('../../assets/img/blog/01.jpg'),
        title: 'Why Hiring an Ideal Mobile App Company',
        paragraph: `>Mobile phones have become as ubiquitous as wristwatches once were. Men, women and even children are today connected to others from across the world, all thanks to the new smartphone technology. Smartphones are virtual computers that you can carry around in your pocket easily. These mobile phones not only allow you to talk to another person but also provide the palm of your hands`
      },
      {
        img: require('../../assets/img/blog/02.jpg'),
        title: 'Mobile Business Apps Are the Next Best',
        paragraph: `>Mobile phones have become as ubiquitous as wristwatches once were. Men, women and even children are today connected to others from across the world, all thanks to the new smartphone technology. Smartphones are virtual computers that you can carry around in your pocket easily. These mobile phones not only allow you to talk to another person but also provide the palm of your hands`
      },
      {
        img: require('../../assets/img/blog/03.jpg'),
        title: 'How To Create a User-Centric App That',
        paragraph: `>Mobile phones have become as ubiquitous as wristwatches once were. Men, women and even children are today connected to others from across the world, all thanks to the new smartphone technology. Smartphones are virtual computers that you can carry around in your pocket easily. These mobile phones not only allow you to talk to another person but also provide the palm of your hands`
      },
      {
        img: require('../../assets/img/blog/04.jpg'),
        title: 'Why Hiring an Ideal Mobile App Company',
        paragraph: `>Mobile phones have become as ubiquitous as wristwatches once were. Men, women and even children are today connected to others from across the world, all thanks to the new smartphone technology. Smartphones are virtual computers that you can carry around in your pocket easily. These mobile phones not only allow you to talk to another person but also provide the palm of your hands`
      }
    ]
  }
  render(){
    return(
      <section className="blog-page-content-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                      {
                        this.state.blogPosts.map( (blog,index) => (
                          <div className="col-lg-12" key={index}>
                              <div className="single-blog-post-item">
                                  <div className="thumb">
                                      <img src={blog.img} alt="blog post"/>
                                      <div className="time">
                                          <span className="date">28</span>
                                          <span className="month">Dec</span>
                                      </div>
                                  </div>
                                  <div className="content">
                                      <ul className="post-meta">
                                          <li><a href="#!"><i className="icofont-speech-comments"></i> 22 Comments</a></li>
                                          <li><a href="#!"><i className="icofont-eye-alt"></i> 466 Views</a></li>
                                      </ul>
                                      <a href="#!"><h4 className="title">{blog.title}</h4></a>
                                          <p>{blog.paragraph}</p>
                                          <a href="blog-single.html" className="readmore">Read More</a>
                                  </div>
                              </div>
                          </div>
                        ))
                      }
                        <div className="col-lg-12">
                            <div className="blog-pagination">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                      <li className="page-item"><a className="page-link" href="#!"><i className="icofont-rounded-double-left"></i></a></li>
                                      <li className="page-item"><a className="page-link" href="#!">1</a></li>
                                      <li className="page-item active"><a className="page-link" href="#!">2</a></li>
                                      <li className="page-item"><a className="page-link" href="#!">3</a></li>
                                      <li className="page-item"><a className="page-link" href="#!"><i className="icofont-rounded-double-right"></i></a></li>
                                    </ul>
                                  </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                  <Sidebar/>
                </div>
            </div>
        </div>
    </section>
    )
  }
}
export default BlogArea;
