import React,{Component} from 'react';
import Navbar from './components/Navbar';
import Breadcrumb from './components/Breadcrumb';
import FooterArea from './components/Footer'
import BlogDetailsArea from './components/BlogDetailsArea';

class BlogDetails extends Component{
  render(){
    return(
      <main rel="main">
        <Navbar navClass="home-2"/>
        <Breadcrumb pageName="Blog Details"/>
        <BlogDetailsArea/>
        <FooterArea/>
      </main>
    )
  }
}

export default BlogDetails;
