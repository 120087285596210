import React,{Component} from 'react';

class Newslatter extends Component {
  render(){
    return (
      <section className="newsletter-aera newsletter-bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="newsletter-inner">
                        <span className="subtitle">Newsletter</span>
                        <h2 className="title">Get The Lates News</h2>
                        <p>Subscribe to our newsletter to receive updates from us.</p>
                        <form action="index.html" className="newsletter-form">
                            <div className="form-group">
                                <input type="email" placeholder="Enter your email" className="form-control"/>
                            </div>
                            <button type="submit" className="submit-btn">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default Newslatter;
