import React,{Component} from "react";
import Navbar from './components/Navbar';
import HeaderFour from './components/Header-4';
import AppFeatures from './components/AppFeatures';
import VideoAndCounter from './components/VideoAndCounter';
import WhatWeOffer from './components/WhatWeOffer';
import HowItWorks from './components/HowItWorks';
import ScreenshortArea from './components/Screenshort';
import PricingArea from './components/PricingArea';
import Newslatter from './components/Newsletter';
import TeamMemberArea from './components/TeamMemberArea';
import Testimonial from './components/Testimonial';
import DownloadArea from './components/DownloadArea';
import ContactArea from './components/ContactArea';
import FooterArea from './components/Footer.js'
import Preloader from './components/Preloader';
import BacktoTop from './components/BacktoTop';


class HomePageFour extends Component{
  render(){
    return (
      <main rel="main">
        <Navbar navClass="home-2"/>
        <HeaderFour/>
        <AppFeatures/>
        <VideoAndCounter/>
        <WhatWeOffer/>
        <HowItWorks/>
        <ScreenshortArea/>
        <PricingArea/>
        <Newslatter/>
        <TeamMemberArea/>
        <Testimonial/>
        <DownloadArea/>
        <ContactArea/>
        <FooterArea/>
        <Preloader/>
        <BacktoTop/>
      </main>
    )
  }
}


export default HomePageFour;
