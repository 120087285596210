import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './apptidy.js';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom';
import HomePageOne from './demos/Home-1';
import HomePageTwo from './demos/Home-2';
import HomePageThree from './demos/Home-3';
import HomePageFour from './demos/Home-4';
import Blog from './demos/Blog';
import BlogDetails from './demos/BlogDetails';

ReactDOM.render(<BrowserRouter basename="/">
  <Switch>
    {/*<Route  path="/" exact render={props => <App {...props}/>}/>*/}
    <Route path="/" exact render={props => <HomePageOne {...props} />} />
    {/*<Route path="/home-2" exact render={props => <HomePageTwo {...props} />} />*/}
    {/*<Route path="/home-3" exact render={props => <HomePageThree {...props} />} />*/}
    {/*<Route path="/home-4" exact render={props => <HomePageFour {...props} />} />*/}
    {/*<Route path="/blog" exact render={props => <Blog {...props} />} />*/}
    {/*<Route path="/blog-details" exact render={props => <BlogDetails {...props} />} />*/}
    <Redirect to="/"/>
  </Switch>
</BrowserRouter>, document.getElementById('root'));
