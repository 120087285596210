import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class HowItWorks extends Component{
  state = {
    sectionTitle: {
      title: '让您的 AR 制作变得简单',
      subtitle: 'Easy to use',
      paragraph: '您只需要简单几个步骤就能发布属于自己的 AR 内容。',
      titleClass: 'p-width-lg'
    },
    howItWorksItems:[
      {
        theme:'bg1',
        icon: 'icofont-cloud-download',
        number: '01',
        title:'选择',
        paragraph: '从 3D 模型库中选择好素材，或者提交本地模型到编辑器中，为内容编辑做好准备。'
      },
      {
        theme:'bg2',
        icon: 'icofont-settings',
        number: '02',
        title:'编辑',
        paragraph: '在 AR 编辑器中调整模型的光照、材质，设置好交互动作。'
      },
      {
        theme:'bg3',
        icon: 'icofont-award',
        number: '03',
        title:'发布',
        paragraph: '将编辑好的内容一键发布到自己的主页中，接下来就可以将成果分享给大家。'
      },
    ]
  }
  render(){
    return (
      <section className="how-it-works">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                  <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleClass={this.state.sectionTitle.titleClass}/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12 text-center">
                      <div className="img-wrapper">
                          <img src={require('../../assets/img/easy2use.png')} alt="hot it works"/>
                      </div>
                  </div>
                  {
                    this.state.howItWorksItems.map((items,index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                          <div className="single-how-it-work ">
                              <div className={`icon ${items.theme}`}>
                                  <i className={items.icon}></i>
                                  <span className="num">{items.number}</span>
                              </div>
                              <div className="content">
                                  <h4 className="title">{items.title}</h4>
                                  <p>{items.paragraph}</p>
                              </div>
                          </div>
                      </div>
                    ))
                  }
              </div>
          </div>
      </section>
    )
  }
}

export default HowItWorks;
