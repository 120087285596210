import React, {Component} from "react";
import Navbar from './components/Navbar';
import HeaderOne from './components/Header-1';
import AppFeatures from './components/AppFeatures';
import VideoAndCounter from './components/VideoAndCounter';
import WhatWeOffer from './components/WhatWeOffer';
import HowItWorks from './components/HowItWorks';
import ScreenshortArea from './components/Screenshort';
import PricingArea from './components/PricingArea';
import Newslatter from './components/Newsletter';
import TeamMemberArea from './components/TeamMemberArea';
import Testimonial from './components/Testimonial';
import DownloadArea from './components/DownloadArea';
import ContactArea from './components/ContactArea';
import FooterArea from './components/Footer.js'
import Preloader from './components/Preloader';
import BacktoTop from './components/BacktoTop';

class HomePageOne extends Component {
    render() {
        return (
            <main rel="main">
                <Navbar navClass="home-1"/>
                <HeaderOne/>
                <AppFeatures/>
                {/*<VideoAndCounter/>*/}
                {/*专属主页*/}
                <WhatWeOffer/>
                {/*编辑器介绍*/}
                <ContactArea/>
                {/*3D素材库介绍*/}
                <PricingArea/>
                {/*怎样使用*/}
                <HowItWorks/>
                {/*截图欣赏*/}
                {/*<ScreenshortArea/>*/}
                {/*<Newslatter/>*/}
                <TeamMemberArea/>
                {/*<Testimonial/>*/}
                {/*<DownloadArea/>*/}
                <FooterArea/>
                <Preloader/>
                <BacktoTop/>
            </main>
        )
    }
}


export default HomePageOne;
