import React,{Component} from 'react';

class HeaderFour extends Component{
  render(){
    return(
      <header className="header-area header-style-five header-bg-3" id="home">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="header-inner">
                        <h1 className="title wow fadeInDown" >Showcase Your App With Apptidy</h1>
                        <p>Apprtidy is the best app landing page which will help you showcase your business, lifestyle, social, or shopping app in the best possible manner.</p>
                        <div className="btn-wrapper" >
                            <a href="#!" className="boxed-btn btn-rounded"><i className="icofont-brand-apple"></i> App Store</a>
                            <a href="#!" className="boxed-btn btn-rounded blank"><img src={require('../../assets/img/icons/google-play.png')} alt="google play"/> Play Store</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                  <div className="header-form-area">
                      <div className="header-form-inner">
                          <h4 className="title">Get A Quote</h4>
                          <form action="#!">
                               <div className="form-group">
                                   <input type="text" className="form-control" placeholder="Enter Your Name.."/>
                               </div>
                               <div className="form-group">
                                   <input type="email" className="form-control" placeholder="Enter Your Email.."/>
                               </div>
                               <div className="form-group">
                                   <input type="text" className="form-control" placeholder="Enter Subject.."/>
                               </div>
                               <div className="form-group textarea">
                                   <textarea className="form-control" cols="30" rows="10" placeholder="Your Message.."></textarea>
                               </div>
                               <button className="submit-btn" type="submit">Send Message</button>
                           </form>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </header>
    )
  }
}

export default HeaderFour;
